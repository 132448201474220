<template>
  <GeneralLayout>
    <template v-slot:header> <GeneralHeader :title="name" /></template>

    <template v-slot:main>
      <TestOptionContainer />
      <SavingInfoBar />
    </template>
  </GeneralLayout>
</template>

<script lang="ts">
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';
import FantasyModule from '@/store/modules/FantasyModule';
import LettersModule from '@/store/modules/LettersModule';
import RiddleModule from '@/store/modules/RiddleModule';
import SyllableModule from '@/store/modules/SyllableModule';
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Form, Test } from '../../api/types';
import SavingInfoBar from '../../components/base/SavingInfoBar.vue';
import TestOptionContainer from '../../components/tests/overview/TestOptionContainer.vue';
import store from '../../store';

@Component({
  components: {
    TestOptionContainer,
    SavingInfoBar,
    GeneralLayout,
    GeneralHeader,
  },
})
export default class TestOptions extends Vue {
  userModule: UserModule = getModule(UserModule, this.$store);
  lettersModule: LettersModule = getModule(LettersModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  syllableModule: SyllableModule = getModule(SyllableModule, this.$store);
  lettersResult = this.lettersModule.result;
  fantasyResult = this.fantasyModule.result;
  syllableResult = this.syllableModule.result;
  wordsResult = this.wordsModule.result;
  riddleResult = this.riddleModule.result;

  get name(): string {
    return this.$route.params.form === 'a' ? 'Form A' : 'Form B';
  }

  async created(): Promise<void> {
    try {
      const chosenForm = this.$route.params.form === 'a' ? Form.a : Form.b;
      this.lettersModule.setForm(chosenForm);
      this.fantasyModule.setForm(chosenForm);
      this.syllableModule.setForm(chosenForm);
      this.wordsModule.setForm(chosenForm);
      this.riddleModule.setForm(chosenForm);
    } catch (error) {
      console.log('SETUP ERROR', error);
    }
  }

  mounted(): void {
    // const shownCongrats =
    //   this.$route.params.form === 'a'
    //     ? this.riddleModule.shownCongratsA
    //     : this.riddleModule.shownCongratsB;

    // if (this.allFinished && !shownCongrats) {
    //   // TODO show overview with alles geschafft, then go to gratulations with super
    //   const congratulations = new Audio('/audio/gratuliere_alles.mp3');
    //   congratulations.addEventListener('ended', () => {
    //     setTimeout(async () => {
    //       this.riddleModule.haveShownCongrats(this.$route.params.form);
    //       router.push({ name: 'congratulations' });
    //     }, 2500);
    //   });
    //   congratulations.play();
    // }

    this.lettersModule.resetTutorial();
    this.fantasyModule.resetTutorial();
    this.syllableModule.resetTutorial();
    this.wordsModule.resetTutorial();
    this.riddleModule.resetTutorial();
  }

  get allFinished(): boolean {
    return (
      this.lettersModule.isDone &&
      this.wordsModule.isDone &&
      this.riddleModule.isDone &&
      this.fantasyModule.isDone &&
      this.syllableModule.isDone
    );
  }

  async beforeRouteLeave(to: any, from: any, next: any): Promise<void> {
    await (store as any).restored;

    // INFO don't stop audio when going to congratulations page???
    if (!to.path.includes('test') && !to.path.includes('gratulation')) {
      this.userModule.stopActiveAudio();
    }

    const lettersDone = to.name === Test.letters && this.lettersModule.isDone;
    const fantasyDone = to.name === Test.fantasy && this.fantasyModule.isDone;
    const syllableDone =
      to.name === Test.syllables && this.syllableModule.isDone;
    const wordsDone = to.name === Test.words && this.wordsModule.isDone;
    const riddleDone = to.name === Test.riddle && this.riddleModule.isDone;

    if (
      (lettersDone || fantasyDone || syllableDone || riddleDone || wordsDone) &&
      process.env.NODE_ENV !== 'development'
    ) {
      next({ name: 'tests' });
    } else {
      next();
    }
  }
}
</script>
